import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Employee, { EmployeeAppFrontPageChoice } from '../../model/employee'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import { hasFlex } from '../../utils/employee-utils'
import {
  showCarAllowance,
  showLeaveRegistration,
  showProjectRegistration,
  showSalaryRegistration,
  showTimeBoxRegistration,
  showTimeRegistrations,
  showWorkHours,
} from '../../utils/footer-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatEmployeeAppFrontPageChoice } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'

type Props = {
  employee: Employee
  company: Company
  projects: List<Project>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  companyFeatures: List<CompanyFeature>
}

export type CardChoiceResult = {
  employeeAppFrontPageChoice: EmployeeAppFrontPageChoice
}

function CardChoiceSwitcherModalForm(
  props: Props & FormComponentProps<CardChoiceResult, CardChoiceResult>
): ReactElement | null {
  const { decorateField } = props

  const options: EmployeeAppFrontPageChoice[] = ['Default']

  if (showWorkHours(props.employee, props.company)) {
    options.push('Work Hours')
  }
  if (showProjectRegistration(props.employee, props.company, props.projects.toArray())) {
    options.push('Project Registration')
  }
  if (showTimeRegistrations(props.employee, props.company)) {
    options.push('Time Registration')
  }
  if (showTimeBoxRegistration(props.employee, props.company, props.leaveTypes.toArray())) {
    options.push('Time Box Registration')
  }
  if (showSalaryRegistration(props.employee, props.salaryTypes.toArray())) {
    options.push('Salary Registration')
  }
  if (showLeaveRegistration(props.employee, props.company)) {
    options.push('Leave Registration')
  }
  if (showCarAllowance(props.employee)) {
    options.push('Car Allowance')
  }
  // TODO: Enable these when their cards have been implemented.
  // if (showOneTimePayCommission(props.employee, props.company)) {
  //   options.push('One Time Pay Commission')
  // }
  // if (showReimbursementVouchers(props.employee, props.company, props.companyFeatures.toArray())) {
  //   options.push('Reimbursement Vouchers')
  // }

  return (
    <>
      <Row>
        <Col span={24}>
          {decorateField('employeeAppFrontPageChoice', {
            placeholder: t('dashboard.choice_card.switcher.form.edit.choice'),
          })(
            <Select dropdownMatchSelectWidth={false} tabIndex={1}>
              {options.map((option) => (
                <Select.Option key={option} value={option}>
                  {formatEmployeeAppFrontPageChoice(option, hasFlex(props.employee, props.leaveTypes.toArray()))}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={2}>
            {t('dashboard.choice_card.switcher.form.edit.submit')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withValidations<Props, CardChoiceResult, CardChoiceResult>({
  mapPropsToFields: (props) => ({
    employeeAppFrontPageChoice: props.employee.employeeAppFrontPageChoice ?? 'Default',
  }),
  onSubmit: (values) => values,
})(CardChoiceSwitcherModalForm)
