import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import ReimbursementVoucher, {
  ReimbursementVoucherMutableDataFields,
  ReimbursementVoucherMutableFields,
} from '../../model/reimbursementVoucher'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import CostCenterAccounting from '../../types/cost-center-accounting'
import ReimbursementVoucherApprovalState from '../../types/reimbursement-voucher-approval-state'
import ReimbursementVoucherBookingState from '../../types/reimbursement-voucher-booking-state'
import { FileChangeEvent } from '../../utils/antd-utils'
import { approveState } from '../../utils/approve-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { formatDate } from '../../utils/date-utils'
import { getPlatform, isAndroid, Platforms } from '../../utils/device-utils'
import { formatReimbursementVoucherApprovalState } from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { secureUrl, url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Upload from '../antd/upload'
import ContextMenu from '../elements/ContextMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import PullToRefresh from '../widgets/PullToRefresh'
import ReimbursementVouchersModal from './ReimbursementVouchersModal'

import './ReimbursementVouchers.css'

export const TEMP_ID = 'temp-id'

type Props = {
  company: Company
  costCenters: List<CostCenter>
  employee: Employee
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getReimbursementVouchers: (companyID: string, employeeID: string) => Promise<ReimbursementVoucher[] | void>
  createReimbursementVoucher: (
    companyID: string,
    fileID: string,
    approved?: boolean
  ) => Promise<ReimbursementVoucher | void>
  cancelCreateReimbursementVoucher: (companyID: string) => void
  updateReimbursementVoucher: (voucher: ReimbursementVoucherMutableFields) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (
    voucher: ReimbursementVoucherMutableDataFields
  ) => Promise<ReimbursementVoucher | void>
  readyReimbursementVouchers: (ids: string[]) => void
  deleteReimbursementVoucher: (id: string) => void
  getExpenseCategories: (companyID: string) => Promise<ExpenseCategory[] | void>
  getCostCenters: (companyID: string) => Promise<CostCenter[] | void>
}

export default function ReimbursementVouchers(props: Props): ReactElement | null {
  type State = {
    modalKey: number
    editing: string | boolean
    editable: boolean
    uploading: boolean
    scanning: boolean
  }
  const [deleting, setDeleting] = useState<string[]>([])
  const [state, setState] = useState<State>({
    modalKey: 1,
    editing: false,
    editable: false,
    uploading: false,
    scanning: false,
  })
  const [captureSet, setCaptureSet] = useState(false)

  const cameraUploadRef = React.useRef<Upload>(null)
  const androidInstance = isAndroid()

  useEffect(() => {
    if (androidInstance && !captureSet) {
      // Hacky way to add capture="true" to file input
      const interval = setInterval(() => {
        const parent = ReactDOM.findDOMNode(cameraUploadRef.current) as Element
        if (parent) {
          const inputs = parent.getElementsByTagName('input')
          if (inputs.length > 0) {
            clearInterval(interval)
            inputs[0].setAttribute('capture', 'true')
          }
        }
      }, 10)
      setCaptureSet(true)
    }
  }, [androidInstance, captureSet])

  const setEditVisibility = (id: string | boolean, editable: boolean) => {
    // Increment modalKey to create a new component
    setState((prev) => ({ ...prev, modalKey: prev.modalKey + 1, editable: editable, editing: id }))
  }

  const { reimbursementVouchers } = props
  const previousReimbursementVouchers = usePrevious(reimbursementVouchers)

  useEffect(() => {
    // Check for save callback
    if (previousReimbursementVouchers && previousReimbursementVouchers.saving && !reimbursementVouchers.saving) {
      // Check for no error occurred
      if (!reimbursementVouchers.error) {
        // Check for temp window open
        if (state.editing !== TEMP_ID) {
          // Close edit modal
          setEditVisibility(false, false)
        }
      }
    }
  })

  const remove = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteReimbursementVoucher(id)
      }
    }
  }

  type ReimbursementVoucherRow = {
    id: string
    approvalState: ReimbursementVoucherApprovalState
    className: string
    state: string
    headline: string
    description?: string
    editable: boolean
  }

  type ReimbursementVoucherRowBox = {
    state: ReimbursementVoucherApprovalState
    vouchers: ReimbursementVoucherRow[]
  }

  const getReimbursementVouchers = () => {
    return props.reimbursementVouchers.reimbursementVouchers
      .filter((voucher) => !deleting.some((id) => id === voucher.id))
      .map((voucher) => {
        let editable = true
        let className = 'registration-row-unapproved'
        if (voucher.approvalState === ReimbursementVoucherApprovalState.APPROVED) {
          className = 'registration-row-approved'
        }
        let state = formatDate(voucher.receiptDate || voucher.createdAt)
        if (voucher.disburseable) {
          state += ' (' + t('reimbursement_vouchers.disburseable') + ')'
        }
        if (
          voucher.bookingState === ReimbursementVoucherBookingState.BOOKED ||
          ((!voucher.disburseable || voucher.immediatePay) &&
            voucher.approvalState === ReimbursementVoucherApprovalState.APPROVED)
        ) {
          // if booked, it is not editable, or if it is (not disburseable OR for immediate pay) and approved
          editable = false
        }
        const description = voucher.expenseCategory?.name
        let headline = '-'
        if (voucher.disburseAmount) {
          const amount = formatCurrency(voucher.disburseAmount, 2)
          if (voucher.amount && voucher.currency !== 'DKK') {
            const foreignAmount = formatCurrency(voucher.amount, 2, voucher.currency)
            headline = `${foreignAmount} (${amount})`
          } else {
            headline = amount
          }
        }
        return {
          id: voucher.id,
          approvalState: voucher.approvalState,
          className,
          state,
          headline,
          description,
          editable,
        }
      })
      .reduce((boxes: ReimbursementVoucherRowBox[], row: ReimbursementVoucherRow) => {
        if (boxes.length === 0) {
          return [{ state: row.approvalState, vouchers: [row] }]
        }
        if (boxes[boxes.length - 1].state !== row.approvalState) {
          return [...boxes, { state: row.approvalState, vouchers: [row] }]
        }
        const last = boxes[boxes.length - 1]
        last.vouchers.push(row)
        boxes[boxes.length - 1] = last
        return boxes
      }, [])
  }

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setState((prev) => ({ ...prev, uploading: true }))
        break
      case 'done':
        if (!e.file.response.data) {
          // no data?  Escape!
          setState((prev) => ({ ...prev, uploading: false }))
          return
        }
        setState((prev) => ({ ...prev, uploading: false, scanning: true }))
        setEditVisibility(TEMP_ID, true)
        setCaptureSet(false)
        props
          .createReimbursementVoucher(props.company.id, e.file.response.data.id, approveState(props.employee))
          .then((res) => {
            if (!res) {
              return
            }
            setState((prev) => {
              if (prev.editing !== false) {
                // Wait half a sec to prevent window from closing
                prev = { ...prev, scanning: false }
                setTimeout(() => {
                  setState((prev) => ({ ...prev, editing: res.id }))
                }, 500)
              }
              return prev
            })
          })
        break
      default:
        break
    }
  }

  const getUploadElement = (locked: boolean) => {
    if (locked) {
      return null
    }
    switch (getPlatform()) {
      case Platforms.ANDROID: {
        // Show context menu on Android native to select either camera or file selector
        return (
          <ContextMenu
            className="reimbursement-vouchers-add"
            style={{ cursor: 'pointer' }}
            text={t('reimbursement_vouchers.add_voucher')}
          >
            <Upload
              ref={cameraUploadRef}
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ authorization: getAccessToken() }}
              accept={'image/*'}
              showUploadList={false}
              onChange={handleFileUpload}
            >
              <a href={'#!'}>{t('reimbursement_vouchers.add_voucher_camera')}</a>
            </Upload>
            <Upload
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ authorization: getAccessToken() }}
              accept={'image/*,.pdf'}
              showUploadList={false}
              onChange={handleFileUpload}
            >
              <a href={'#!'}>{t('reimbursement_vouchers.add_voucher_file')}</a>
            </Upload>
          </ContextMenu>
        )
      }
      case Platforms.IOS:
      case Platforms.WEB:
      default: {
        // accept="image/*" allows the user to select either camera or existing photo
        // Tested on
        // - Android Chrome (overlay with camera roll, camera and file selector)
        // - iOS native (context menu with photo library, camera and file selector)
        // - iOS Safari (context menu with photo library, camera and file selector)
        // - Desktop Chrome (file selector)
        // - Desktop Safari (file selector)
        return (
          <Upload
            name={'fileData'}
            action={url('v2/stagedFiles')}
            headers={{ authorization: getAccessToken() }}
            accept={'image/*,.pdf'}
            showUploadList={false}
            onChange={handleFileUpload}
          >
            <div className="reimbursement-vouchers-add">{t('reimbursement_vouchers.add_voucher')}</div>
          </Upload>
        )
      }
    }
  }

  const reimbursements = getReimbursementVouchers()
  const locked = !props.employee.earliestMutableContract
  return (
    <div className={'reimbursement-vouchers registrations-list platform-' + getPlatform()}>
      <PullToRefresh
        onRefresh={() => {
          const employee = props.employee
          const companyID = props.company.id
          return Promise.all([
            props.getExpenseCategories(companyID),
            props.getReimbursementVouchers(companyID, employee.id),
            props.getCostCenters(companyID),
          ])
        }}
      >
        {reimbursements.map((row) => {
          return (
            <div key={row.state}>
              <div className="reimbursement-vouchers-header">{formatReimbursementVoucherApprovalState(row.state)}</div>
              {row.vouchers.map((voucher) => {
                return (
                  <div key={voucher.id} className="registration-row">
                    <Card className={voucher.className}>
                      <div
                        className={'registration-row-info'}
                        onClick={() => setEditVisibility(voucher.id, voucher.editable)}
                      >
                        <LazyLoadImage
                          src={secureUrl(`v2/reimbursementVouchers/${voucher.id}/thumbnail`)}
                          alt=""
                          className="reimbursement-voucher-thumbnail"
                          height={80}
                          width={80}
                        />
                        <div className="reimbursement-voucher-date">{voucher.state}</div>
                        <div className="reimbursement-voucher-headline">{voucher.headline}</div>
                        <div className="reimbursement-voucher-description">{voucher.description}</div>
                      </div>
                      {voucher.editable && !locked && (
                        <span onClick={remove(voucher.id)} className="registration-row-delete" />
                      )}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {reimbursements.length === 0 && (
          <div className="content-text">{t('reimbursement_vouchers.no_reimbursement_vouchers_yet')}</div>
        )}
      </PullToRefresh>
      {getUploadElement(locked)}

      {(state.uploading || props.reimbursementVouchers.saving) && <LoadingOverlay />}

      <Modal
        key={state.modalKey}
        visible={state.editing !== false}
        closable={true}
        onOk={() => setEditVisibility(false, false)}
        onCancel={() => {
          setEditVisibility(false, false)
          props.cancelCreateReimbursementVoucher(props.company.id)
        }}
        footer={null}
      >
        <ReimbursementVouchersModal
          visible={state.editing !== false}
          editable={locked ? false : state.editable}
          waitForScan={state.scanning}
          waitForCreation={state.editing === TEMP_ID}
          immediatePayEnabled={props.company.enableImmediatePay}
          employee={props.employee}
          reimbursementVoucherID={typeof state.editing === 'string' ? state.editing : undefined}
          expenseCategories={props.expenseCategories}
          reimbursementVouchers={props.reimbursementVouchers}
          costCenters={props.costCenters}
          costCenterAccounting={props.company.costCenterAccounting || CostCenterAccounting.OFF}
          updateReimbursementVoucher={props.updateReimbursementVoucher}
          updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
          readyReimbursementVouchers={props.readyReimbursementVouchers}
          closeModal={() => setEditVisibility(false, false)}
        />
      </Modal>
    </div>
  )
}
