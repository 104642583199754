import Company from '../model/company'
import CompanyFeature from '../model/companyFeature'
import Employee from '../model/employee'
import LeaveType from '../model/leaveType'
import Project from '../model/project'
import SalaryType from '../model/salaryType'
import { paths } from '../routes'
import AffiliationType from '../types/affiliation-type'
import CompanySetting from '../types/company-setting'
import { hasDetailedCarAllowance, hasFlex, hasOvertime, hasSalaryRegistration, isHourlyPaid } from './employee-utils'
import { projectFilter } from './projects-utils'
import { t } from './translation-utils'

export type MenuItem = {
  path: string
  paths?: string[]
  title: string
}

export function showWorkHours(employee: Employee, company: Company) {
  return (
    !isHourlyPaid(employee) &&
    company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursFeature') &&
    !employee.disableWorkHours
  )
}

export function showProjectRegistration(employee: Employee, company: Company, projects: Project[]) {
  return (
    !isHourlyPaid(employee) &&
    projects.some((p) => projectFilter(p, employee)) &&
    !company.settingsEnabled.some((setting) => setting === CompanySetting.DISABLE_APP_PROJECTS_REGISTRATION)
  )
}

export function showTimeRegistrations(employee: Employee, company: Company) {
  return (
    isHourlyPaid(employee) &&
    !company.settingsEnabled.some((setting) => setting === CompanySetting.DISABLE_APP_TIME_REGISTRATION) &&
    employee.activeContract?.timeRegistrationMethodType === 'Detailed'
  )
}

export function showTimeBoxRegistration(employee: Employee, company: Company, leaveTypes: LeaveType[]) {
  return (
    (hasFlex(employee, leaveTypes) || hasOvertime(employee, leaveTypes)) &&
    !company.settingsEnabled.some((setting) => setting === CompanySetting.DISABLE_APP_FLEX_OVERTIME_REGISTRATION)
  )
}

export function showSalaryRegistration(employee: Employee, salaryTypes: SalaryType[]) {
  return (
    hasSalaryRegistration(employee, salaryTypes) && employee.activeContract?.salaryRegistrationMethodType === 'Detailed'
  )
}

function isFreelancer(employee: Employee) {
  return employee.affiliationType === AffiliationType.FREELANCER
}

export function showLeaveRegistration(employee: Employee, company: Company) {
  return (
    (isHourlyPaid(employee) &&
      company.settingsEnabled.some((setting) => setting === CompanySetting.ENABLE_APP_HOURLY_LEAVE_REGISTRATION)) ||
    (!company.settingsEnabled.some((setting) => setting === CompanySetting.DISABLE_APP_LEAVE_REGISTRATION) &&
      !isHourlyPaid(employee) &&
      !isFreelancer(employee))
  )
}

export function showCarAllowance(employee: Employee) {
  return (
    !isFreelancer(employee) &&
    hasDetailedCarAllowance(employee) &&
    employee.activeContract?.carAllowanceRegistrationMethodType === 'Detailed'
  )
}

export function showOneTimePayCommission(employee: Employee, company: Company) {
  return (
    isFreelancer(employee) &&
    company.settingsEnabled.some((setting) => setting === CompanySetting.ENABLE_APP_FEE_REGISTRATION)
  )
}

export function showReimbursementVouchers(employee: Employee, company: Company, companyFeatures: CompanyFeature[]) {
  return (
    companyFeatures.some((feature) => feature.featureType === 'Reimbursement Vouchers') &&
    company.enableReimbursementVouchers &&
    !employee.disableReimbursementVouchers
  )
}

export function getFooterItems(
  employee: Employee,
  company: Company,
  companyFeatures: CompanyFeature[],
  leaveTypes: LeaveType[],
  salaryTypes: SalaryType[],
  projects: Project[]
): MenuItem[] {
  const items: MenuItem[] = []

  if (showWorkHours(employee, company)) {
    items.push({ path: '/' + paths.WORK_HOURS_REGISTRATION, title: t('footer.work_hours_registration') })
  }
  if (showProjectRegistration(employee, company, projects)) {
    items.push({ path: '/' + paths.PROJECT_REGISTRATION, title: t('footer.project_registration') })
  }
  if (showTimeRegistrations(employee, company)) {
    items.push({
      path: '/' + paths.TIME_REGISTRATION,
      title: t('footer.time_registration'),
    })
  }
  if (showTimeBoxRegistration(employee, company, leaveTypes)) {
    items.push({
      path: '/' + paths.TIME_BOX_REGISTRATION,
      title: hasFlex(employee, leaveTypes) ? t('footer.flex_registration') : t('footer.overtime_registration'),
    })
  }
  if (showSalaryRegistration(employee, salaryTypes)) {
    items.push({
      path: '/' + paths.SALARY_REGISTRATION,
      title: t('footer.salary_registration'),
    })
  }
  if (showLeaveRegistration(employee, company)) {
    items.push({
      path: '/' + paths.LEAVE_REGISTRATION,
      paths: ['/' + paths.LEAVE_STATISTICS],
      title: t('footer.leave_registration'),
    })
  }
  if (showCarAllowance(employee)) {
    items.push({ path: '/' + paths.CAR_ALLOWANCE, title: t('footer.car_allowance') })
  }
  if (showOneTimePayCommission(employee, company)) {
    items.push({ path: '/' + paths.FEES, title: t('footer.fees') })
  }
  if (showReimbursementVouchers(employee, company, companyFeatures)) {
    items.push({ path: '/' + paths.REIMBURSEMENT_VOUCHERS, title: t('footer.reimbursement_vouchers') })
  }
  items.push({ path: '/' + paths.PAY_SLIPS, paths: ['/' + paths.DOCUMENTS], title: t('footer.documents') })
  return items
}
